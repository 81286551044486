const translate = {
	messages: {
		ru: {
			'Публичный договор о предоставлении посреднических услуг.': 'Публичный договор о предоставлении посреднических услуг.',
			'Физическое лицо (В дальнейшем «Сторона-1»), с одной стороны, и Общество с ограниченной ответственностью «Интернет Реклама РИА» с другой стороны, (в дальнейшем именуется "Сторона-2»).': 'Физическое лицо (В дальнейшем «Сторона-1»), с одной стороны, и Общество с ограниченной ответственностью «Интернет Реклама РИА» с другой стороны, (в дальнейшем именуется "Сторона-2»).',
			'Учитывая, что:': 'Учитывая, что:',
			'Сторона-2 реализует программу "Проверьте авто”  на AUTO.RIA (далее Программа), которая является неотъемлемой частью настоящего Договора, среди пользователей сайта AUTO.RIA.com (далее сайт);': 'Сторона-2 реализует программу "Проверьте авто”  на AUTO.RIA (далее Программа), которая является неотъемлемой частью настоящего Договора, среди пользователей сайта AUTO.RIA.com (далее сайт);',
			'Сторона-1, которая является продавцом транспортного средства и желает воспользоваться Программой, подала заявку об этом на сайте;': 'Сторона-1, которая является продавцом транспортного средства и желает воспользоваться Программой, подала заявку об этом на сайте;',
			'Стороны договорились о следующем:': 'Стороны договорились о следующем:',
			'1. Предмет договора.': '1. Предмет договора.',
			'1.1. По настоящему Договору Сторона-2 обязуется предоставить Стороне-1 посреднические услуги путем осуществления от имени и за счет Стороны-1 таких юридических и фактических действий:': '1.1. По настоящему Договору Сторона-2 обязуется предоставить Стороне-1 посреднические услуги путем осуществления от имени и за счет Стороны-1 таких юридических и фактических действий:',
			'1.1.1. Провести переговоры с сертифицированной станцией технического обслуживания и/или с Сервисным центром МВД, с которой у Стороны-2 заключен договор (далее СТО и/или Сервисный центр МВД) по согласованию даты и времени проведения проверки транспортного средства. При этом, Сторона-1 самостоятельно заключает договор с СТО и/или Сервисным центром МВД и оплачивает услуги по проверке.': '1.1.1. Провести переговоры с сертифицированной станцией технического обслуживания и/или с Сервисным центром МВД, с которой у Стороны-2 заключен договор (далее СТО и/или Сервисный центр МВД) по согласованию даты и времени проведения проверки транспортного средства. При этом, Сторона-1 самостоятельно заключает договор с СТО и/или Сервисным центром МВД и оплачивает услуги по проверке.',
			'1.1.2. Передать заявку Стороны-1, представленную на сайте, СТО и/или Сервисному центру МВД для выполнения услуги по проверке;': '1.1.2. Передать заявку Стороны-1, представленную на сайте, СТО и/или Сервисному центру МВД для выполнения услуги по проверке;',
			'1.1.3. Разместить публикацию результатов проверки на сайте рядом с объявлением о продаже транспортного средства в случае осуществления оплаты Стороной-1 в порядке и в размере предусмотренном п.3.1.3. Настоящего Договора.': '1.1.3. Разместить публикацию результатов проверки на сайте рядом с объявлением о продаже транспортного средства в случае осуществления оплаты Стороной-1 в порядке и в размере предусмотренном п.3.1.3. Настоящего Договора.',
			'1.1.4. Принять услуги от СТО и/или Сервисного центра МВД и подписать  акт приема-передачи услуг.': '1.1.4. Принять услуги от СТО и/или Сервисного центра МВД и подписать  акт приема-передачи услуг.',
			'1.2. Данный договор является публичной офертой.': '1.2. Данный договор является публичной офертой.',
			'1.3. Настоящий договор считается заключенным со Стороной-1, когда со стороны Сторона-1 совершенные следующие действия согласно настоящему Договору: заполненная заявка на осуществление проверки и осуществленная оплата.': '1.3. Настоящий договор считается заключенным со Стороной-1, когда со стороны Сторона-1 совершенные следующие действия согласно настоящему Договору: заполненная заявка на осуществление проверки и осуществленная оплата.',
			'1.4. Основанием для осуществления Стороной-2 полномочий в интересах Стороны-1 является данный договор.': '1.4. Основанием для осуществления Стороной-2 полномочий в интересах Стороны-1 является данный договор.',
			'2. Обязанности Стороны-2.': '2. Обязанности Стороны-2.',
			'2.1. Сторона-2 обязана:': '2.1. Сторона-2 обязана:',
			'2.1.1. осуществить юридические и фактические действия, указанные в п. 1 настоящего Договора, в соответствии с требованиями Стороны-1 указанными в заявке;': '2.1.1. осуществить юридические и фактические действия, указанные в п. 1 настоящего Договора, в соответствии с требованиями Стороны-1 указанными в заявке;',
			'2.1.2. уведомлять Сторону-1 по ее требованию о ходе выполнения её поручения по настоящему Договору;': '2.1.2. уведомлять Сторону-1 по ее требованию о ходе выполнения её поручения по настоящему Договору;',
			'3. Обязанности Стороны-1.': '3. Обязанности Стороны-1.',
			'3.1. Сторона-1 обязана:': '3.1. Сторона-1 обязана:',
			'3.1.1. Прибыть с транспортным средством, заявленным для продажи на сайте, в согласованное время на СТО.': '3.1.1. Прибыть с транспортным средством, заявленным для продажи на сайте, в согласованное время на СТО.',
			'3.1.2. Перечислить Стороне-2 средства, необходимые для выполнения поручения, в том числе, для оплаты услуг СТО и/или Сервисного центра МВД, подписать акт приема-передачи услуг.': '3.1.2. Перечислить Стороне-2 средства, необходимые для выполнения поручения, в том числе, для оплаты услуг СТО и/или Сервисного центра МВД, подписать акт приема-передачи услуг.',
			'3.1.3. В случае заказа услуги по публикации на сайте результатов проверки транспортного средства, оплаты 200 гривен с НДС в пользу Стороны-2 и подписать акт приема-передачи услуг.': '3.1.3. В случае заказа услуги по публикации на сайте результатов проверки транспортного средства, оплаты 200 гривен с НДС в пользу Стороны-2 и подписать акт приема-передачи услуг.',
			'3.1.4. Немедленно принять от Стороны-2 все результаты выполнения последней своих обязательств по настоящему Договору.': '3.1.4. Немедленно принять от Стороны-2 все результаты выполнения последней своих обязательств по настоящему Договору.',
			'3.1.5. В случае, если после проведения проверки с транспортным средством состоялись любые изменения, в частности, поломки, дорожно-транспортные происшествия, Сторона-1 обязана в течение 1 дня сообщить об этом Стороне-2.': '3.1.5. В случае, если после проведения проверки с транспортным средством состоялись любые изменения, в частности, поломки, дорожно-транспортные происшествия, Сторона-1 обязана в течение 1 дня сообщить об этом Стороне-2.',
			'4. Ответственность сторон. Споры.': '4. Ответственность сторон. Споры.',
			'4.1. В случае нарушения своих обязательств по настоящему Договору Стороны несут ответственность согласно действующему законодательству.': '4.1. В случае нарушения своих обязательств по настоящему Договору Стороны несут ответственность согласно действующему законодательству.',
			'4.2. Сторона-2 является посредником и не несет ответственности за качество и достоверность предоставленных услуг по проверке.': '4.2. Сторона-2 является посредником и не несет ответственности за качество и достоверность предоставленных услуг по проверке.',
			'4.3. В случае нарушения Стороной-1 своих обязательств, предусмотренных п.3 настоящего Договора, Сторона-2 имеет право блокировать аккаунт Стороны-1 на сайте.': '4.3. В случае нарушения Стороной-1 своих обязательств, предусмотренных п.3 настоящего Договора, Сторона-2 имеет право блокировать аккаунт Стороны-1 на сайте.',
			'4.4. Ответственность за качество и достоверность предоставленной услуги проверки перед Стороной-1 несет СТО производящее проверку, согласно договора заключенного между СТО и Стороной-2. Сторона-2 в случае возникновения претензий Стороны-1 к СТО обязана по запросу Стороны-1 предоставить документы или копии документов, находящиеся у Стороны-2 и имеющие отношения к возникшему спору между Стороной-1 и СТО.': '4.4. Ответственность за качество и достоверность предоставленной услуги проверки перед Стороной-1 несет СТО производящее проверку, согласно договора заключенного между СТО и Стороной-2. Сторона-2 в случае возникновения претензий Стороны-1 к СТО обязана по запросу Стороны-1 предоставить документы или копии документов, находящиеся у Стороны-2 и имеющие отношения к возникшему спору между Стороной-1 и СТО.',
			'4.5. Все споры, связанные с Договором, разрешаются в порядке, определенном действующим законодательством Украины.': '4.5. Все споры, связанные с Договором, разрешаются в порядке, определенном действующим законодательством Украины.',
			'5. Дополнительные условия.': '5. Дополнительные условия.',
			'5.1. Сторона-2 вправе передать исполнение поручения другому лицу по собственному усмотрению;': '5.1. Сторона-2 вправе передать исполнение поручения другому лицу по собственному усмотрению;',
			'6. Срок действия договора и другие условия.': '6. Срок действия договора и другие условия.',
			'6.1. Настоящий Договор вступает в силу со дня подачи заявки Стороны-1 Стороне-2 на проведение проверки транспортного средства, а в части публикации результатов проверки на сайте - с момента оплаты в соответствии с п. 3.1.3. Данного Договора. Данный договор заключается в соответствии с ч.2 ст. 642 Гражданского Кодекса Украины и не подлежит подписанию его сторонами.': '6.1. Настоящий Договор вступает в силу со дня подачи заявки Стороны-1 Стороне-2 на проведение проверки транспортного средства, а в части публикации результатов проверки на сайте - с момента оплаты в соответствии с п. 3.1.3. Данного Договора. Данный договор заключается в соответствии с ч.2 ст. 642 Гражданского Кодекса Украины и не подлежит подписанию его сторонами.'
		},
		uk: {
			'Публичный договор о предоставлении посреднических услуг.': 'Публічний договір про надання посередницьких послуг.',
			'Физическое лицо (В дальнейшем «Сторона-1»), с одной стороны, и Общество с ограниченной ответственностью «Интернет Реклама РИА» с другой стороны, (в дальнейшем именуется "Сторона-2»).': 'Фізична особа (Надалі «Сторона-1»), з одного боку, і Товариство з обмеженою відповідальністю «Інтернет Реклама РІА» з іншого боку, (в подальшому іменується "Сторона-2»).',
			'Учитывая, что:': 'Враховуючи що:',
			'Сторона-2 реализует программу "Проверьте авто”  на AUTO.RIA (далее Программа), которая является неотъемлемой частью настоящего Договора, среди пользователей сайта AUTO.RIA.com (далее сайт);': 'Сторона-2 реалізує програму "Перевірте авто" на AUTO.RIA (далі Програма), яка є невід\'ємною частиною цього Договору, серед користувачів сайту AUTO.RIA.com (далі сайт);',
			'Сторона-1, которая является продавцом транспортного средства и желает воспользоваться Программой, подала заявку об этом на сайте;': 'Сторона-1, яка є продавцем транспортного засобу і бажає скористатися Програмою, подала заявку про це на сайті;',
			'Стороны договорились о следующем:': 'Сторони домовилися про наступне:',
			'1. Предмет договора.': '1. Предмет договору.',
			'1.1. По настоящему Договору Сторона-2 обязуется предоставить Стороне-1 посреднические услуги путем осуществления от имени и за счет Стороны-1 таких юридических и фактических действий:': '1.1. За цим Договором Сторона-2 зобов\'язується надати Стороні-1 посередницькі послуги шляхом здійснення від імені та за рахунок Сторони-1 таких юридичних та фактичних дій:',
			'1.1.1. Провести переговоры с сертифицированной станцией технического обслуживания и/или с Сервисным центром МВД, с которой у Стороны-2 заключен договор (далее СТО и/или Сервисный центр МВД) по согласованию даты и времени проведения проверки транспортного средства. При этом, Сторона-1 самостоятельно заключает договор с СТО и/или Сервисным центром МВД и оплачивает услуги по проверке.': '1.1.1. Провести переговори з сертифікованою станцією технічного обслуговування та/або з сервісним центром МВС, з якої у Сторони-2 укладено договір (далі СТО та/або Сервісний центр МВС) стосовно погодження дати і часу проведення перевірки транспортного засобу. При цьому, Сторона-1 самостійно укладає договір з СТО та/або сервісним центром МВС і оплачує послуги з перевірки.',
			'1.1.2. Передать заявку Стороны-1, представленную на сайте, СТО и/или Сервисному центру МВД для выполнения услуги по проверке;': '1.1.2. Передати заявку Сторони-1, представлену на сайті, СТО та/або Сервісному центру МВС для виконання послуги з перевірки;',
			'1.1.3. Разместить публикацию результатов проверки на сайте рядом с объявлением о продаже транспортного средства в случае осуществления оплаты Стороной-1 в порядке и в размере предусмотренном п.3.1.3. Настоящего Договора.': '1.1.3. Розмістити публікацію результатів перевірки на сайті поруч з оголошенням про продаж транспортного засобу в разі здійснення оплати Стороною-1 в порядку і в розмірі передбаченому п.3.1.3. Справжньої угоди.',
			'1.1.4. Принять услуги от СТО и/или Сервисного центра МВД и подписать  акт приема-передачи услуг.': '1.1.4. Прийняти послуги від СТО та/або Сервісного центру МВС і підписати акт прийому-передачі послуг.',
			'1.2. Данный договор является публичной офертой.': '1.2. Даний договір є публічною офертою.',
			'1.3. Настоящий договор считается заключенным со Стороной-1, когда со стороны Сторона-1 совершенные следующие действия согласно настоящему Договору: заполненная заявка на осуществление проверки и осуществленная оплата.': '1.3. Цей договір вважається укладеним зі Стороною-1, коли з боку Сторона-1 вчинені такі дії згідно з цим Договором: заповнена заявка на здійснення перевірки і здійснена оплату.',
			'1.4. Основанием для осуществления Стороной-2 полномочий в интересах Стороны-1 является данный договор.': '1.4. Підставою для здійснення Стороною-2 повноважень в інтересах Сторони-1 є даний договір.',
			'2. Обязанности Стороны-2.': '2. Обов\'язки Сторони-2.',
			'2.1. Сторона-2 обязана:': '2.1. Сторона-2 зобов\'язана:',
			'2.1.1. осуществить юридические и фактические действия, указанные в п. 1 настоящего Договора, в соответствии с требованиями Стороны-1 указанными в заявке;': '2.1.1. здійснити юридичні та фактичні дії, зазначені в п. 1 цього Договору, відповідно до вимог Сторони-1 зазначених в заявці;',
			'2.1.2. уведомлять Сторону-1 по ее требованию о ходе выполнения её поручения по настоящему Договору;': '2.1.2. повідомляти Сторону-1 на її вимогу про хід виконання її доручення за цим Договором;',
			'3. Обязанности Стороны-1.': '3. Обов\'язки Сторони-1.',
			'3.1. Сторона-1 обязана:': '3.1. Сторона-1 зобов\'язана:',
			'3.1.1. Прибыть с транспортным средством, заявленным для продажи на сайте, в согласованное время на СТО.': '3.1.1. Прибути з транспортним засобом, заявленим для продажу на сайті, в узгоджений час на СТО.',
			'3.1.2. Перечислить Стороне-2 средства, необходимые для выполнения поручения, в том числе, для оплаты услуг СТО и/или Сервисного центра МВД, подписать акт приема-передачи услуг.': '3.1.2. Перерахувати Стороні-2 кошти, необхідні для виконання доручення, в тому числі, для оплати послуг СТО та/або Сервісного центру МВС, підписати акт прийому-передачі послуг.',
			'3.1.3. В случае заказа услуги по публикации на сайте результатов проверки транспортного средства, оплаты 200 гривен с НДС в пользу Стороны-2 и подписать акт приема-передачи услуг.': '3.1.3. У разі замовлення послуги з публікації на сайті результатів перевірки транспортного засобу, оплатити 200 гривень з ПДВ на користь Сторони-2 і підписати акт прийому-передачі послуг.',
			'3.1.4. Немедленно принять от Стороны-2 все результаты выполнения последней своих обязательств по настоящему Договору.': '3.1.4. Негайно прийняти від Сторони-2 всі результати виконання останньою своїх зобов\'язань за цим Договором.',
			'3.1.5. В случае, если после проведения проверки с транспортным средством состоялись любые изменения, в частности, поломки, дорожно-транспортные происшествия, Сторона-1 обязана в течение 1 дня сообщить об этом Стороне-2.': '3.1.5. У разі, якщо після проведення перевірки з транспортним засобом стались будь-які зміни, зокрема, поломки, дорожньо-транспортні пригоди, Сторона-1 зобов\'язана протягом 1 дня повідомити про це Стороні-2.',
			'4. Ответственность сторон. Споры.': '4. Відповідальність сторін. Спори.',
			'4.1. В случае нарушения своих обязательств по настоящему Договору Стороны несут ответственность согласно действующему законодательству.': '4.1. У разі порушення своїх зобов\'язань за цим Договором Сторони несуть відповідальність згідно з чинним законодавством.',
			'4.2. Сторона-2 является посредником и не несет ответственности за качество и достоверность предоставленных услуг по проверке.': '4.2. Сторона-2 є посередником і не несе відповідальності за якість і достовірність наданих послуг з перевірки.',
			'4.3. В случае нарушения Стороной-1 своих обязательств, предусмотренных п.3 настоящего Договора, Сторона-2 имеет право блокировать аккаунт Стороны-1 на сайте.': '4.3. У разі порушення Стороною-1 своїх зобов\'язань, передбачених п.3 цього Договору, Сторона-2 має право блокувати аккаунт Сторони-1 на сайті.',
			'4.4. Ответственность за качество и достоверность предоставленной услуги проверки перед Стороной-1 несет СТО производящее проверку, согласно договора заключенного между СТО и Стороной-2. Сторона-2 в случае возникновения претензий Стороны-1 к СТО обязана по запросу Стороны-1 предоставить документы или копии документов, находящиеся у Стороны-2 и имеющие отношения к возникшему спору между Стороной-1 и СТО.': '4.4. Відповідальність за якість і достовірність наданої послуги перевірки перед Стороною-1 несе СТО, яке здійснює перевірку, згідно договору укладеного між СТО і Стороною-2. Сторона-2 в разі виникнення претензій Сторони-1 до СТО зобов\'язана на вимогу Сторони-1 надати документи або копії документів, що знаходяться у Сторони-2 і мають відношення до спору, що виник між Стороною-1 і СТО.',
			'4.5. Все споры, связанные с Договором, разрешаются в порядке, определенном действующим законодательством Украины.': '4.5. Всі суперечки, пов\'язані з Договором, вирішуються в порядку, визначеному чинним законодавством України.',
			'5. Дополнительные условия.': '5. Додаткові умови.',
			'5.1. Сторона-2 вправе передать исполнение поручения другому лицу по собственному усмотрению;': '5.1. Сторона-2 вправі передати виконання доручення іншій особі на власний розсуд;',
			'6. Срок действия договора и другие условия.': '6. Термін дії договору та інші умови.',
			'6.1. Настоящий Договор вступает в силу со дня подачи заявки Стороны-1 Стороне-2 на проведение проверки транспортного средства, а в части публикации результатов проверки на сайте - с момента оплаты в соответствии с п. 3.1.3. Данного Договора. Данный договор заключается в соответствии с ч.2 ст. 642 Гражданского Кодекса Украины и не подлежит подписанию его сторонами.': '6.1. Цей Договір набирає чинності з дня подачі заявки Сторони-1 Стороні-2 на проведення перевірки транспортного засобу, а в частині публікації результатів перевірки на сайті — з моменту оплати відповідно до п. 3.1.3. Даного Договору. Даний договір укладається відповідно до ч.2 ст. 642 Цивільного Кодексу України і не підлягає підписанню його сторонами.'
		}
	}
};
export default translate;
